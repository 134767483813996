import { render, staticRenderFns } from "./REdit.vue?vue&type=template&id=219c7295&scoped=true"
import script from "./REdit.vue?vue&type=script&lang=js"
export * from "./REdit.vue?vue&type=script&lang=js"
import style0 from "./REdit.vue?vue&type=style&index=0&id=219c7295&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219c7295",
  null
  
)

export default component.exports