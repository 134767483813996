<template>
  <div>
    <validation-observer v-slot="{ handleSubmit }">
      <div class="columns">
        <div class="column is-3">
          <card-component
            title="1. Select a dataset and an action"
            class="tile is-child"
            :displayHelp="$options.name"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules= {required:true}
              name="dataset"
            >
              <dataset-select
                label="Dataset"
                :message="errors"
                :type="{ 'is-danger': errors[0] }"
                :dataset.sync="datasetName"
                v-model="datasetName"
                :withVarname="false"
                icon="table"
                pack="fa"
              />
            </validation-provider>
            <hr />
            <b-field label="Actions" />
            <b-radio v-model="action"
              native-value="transpose"
              type="is-primary">
              Transpose
            </b-radio>
            <br />
            <b-radio v-model="action"
              native-value="set_dataset_nature"
              type="is-primary">
              Change dataset nature
            </b-radio>
            <br />
            <b-radio v-model="action"
              native-value="set_columns_type"
              type="is-primary">
              Change variable (column) types
            </b-radio>
            <br />
            <b-radio v-model="action"
              native-value="set_rownames"
              type="is-primary">
              Set individual (row) names
            </b-radio>
            <br />
            <b-radio v-model="action"
              native-value="subsetc"
              type="is-primary">
              Subset variables (columns)
            </b-radio>
            <br />
            <b-radio v-model="action"
              native-value="subsetr"
              type="is-primary">
              Subset individuals (rows) by name
            </b-radio>
            <br />
            <b-tooltip
              target="filter_categories"
              :triggers="triggerDisabled"
              :label="labelDisabled">
            <b-radio v-model="action"
              id="filter_categories"
              native-value="filter_categories"
              type="is-primary"
              :disabled="!hasCatVariables">
              Subset individuals (rows) by category
            </b-radio>
            </b-tooltip>
            <br />
            <b-tooltip
              target="recode_categories"
              :triggers="triggerDisabled"
              :label="labelDisabled">
            <b-radio v-model="action"
              native-value="recode_categories"
              type="is-primary"
              :disabled="!hasCatVariables">
              Rename categories
            </b-radio>
            </b-tooltip>
            <br />
            <b-tooltip
              target="reorder_categories"
              :triggers="triggerDisabled"
              :label="labelDisabled">
            <b-radio v-model="action"
              native-value="reorder_categories"
              type="is-primary"
              :disabled="!hasCatVariables">
              Reorder categories
            </b-radio>
            </b-tooltip>
          </card-component>
          <br />
          <card-component
            title="History"
            class="tile is-child"
          >
            <ul v-if="history.length > 0" class="striped-list">
              <li v-for="(item,index) in history" :key="index">
                <span v-if="item.length < 25">
                  {{ item }}
                </span>
                <span v-else>
                  <b-tooltip multilined position="is-right" size="is-large" :label="item">
                    {{ item | truncateEntry(25) }}
                  </b-tooltip>
                </span>
                <b-button
                  style="float:right !important;"
                  rounded
                  icon-left="undo"
                  v-if="index===history.length-1"
                  @click="undo()"
                  size="is-small"
                />
              </li>
            </ul>
            <span v-else>No history to display</span>
            <hr v-if="history.length > 0" />
            <div v-if="history.length > 0" class="columns">
              <div class="column">
                <b-button
                  rounded
                  expanded
                  @click="resetHistory"
                  icon-left="times"
                >Reset</b-button>
              </div>
              <div class="column">
                <b-button
                  rounded
                  class="button is-primary"
                  expanded
                  @click="saveDataset"
                  icon-left="floppy-o"
                >Save</b-button>
              </div>
            </div>
          </card-component>
        </div>
        <div class="column">
          <error :type='"notifications"' ref="notifications"></error>
          <card-component
            :title="editionTitle"
          >
            <div v-if="datasetName">
              <div v-if="!action" >
                Select an action.
              </div>
              <div v-if="action">
                <div
                v-if="action==='set_dataset_nature'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules= {required:true}
                    name="dataset nature"
                  >
                    <b-field
                      label="Dataset nature"
                      :message="errors"
                      :type="{ 'is-danger': errors[0] }"
                    >
                      <b-select
                        v-model="dataset_nature"
                        placeholder="Dataset nature"
                        :type="{ 'is-danger': errors[0] }"
                        expanded
                      >
                        <option
                          v-for="(cnature,index) in datasetNatures"
                          :key="index"
                          :value="cnature.key"
                        >
                          {{ cnature.nature }}
                        </option>
                      </b-select>
                    </b-field>
                  </validation-provider>
                </div>
                <div
                  v-if="action==='set_columns_type' || action==='subsetr' || action==='subsetc'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules= {required:true}
                    name="column"
                  >
                    <dual-list-box
                      :message="errors"
                      :type="{ 'is-danger': errors[0] }"
                      :sourceList.sync="sourceList"
                      :targetList.sync="targetList"
                      v-model="targetList"
                    />
                  </validation-provider>
                  <hr />
                  <validation-provider
                    v-if="action==='set_columns_type'"
                    v-slot="{ errors }"
                    :rules= {required:true}
                    name="columns type"
                  >
                    <b-field
                      label="Columns type"
                      :message="errors"
                      :type="{ 'is-danger': errors[0] }"
                    >
                      <b-select
                        v-model="columns_type"
                        placeholder="Columns type"
                        expanded
                      >
                        <option value="numeric">numeric</option>
                        <option value="character">categorical</option>
                      </b-select>
                    </b-field>
                  </validation-provider>
                </div>
                <div
                  v-else-if="action==='recode_categories'"
                  class="columns"
                >
                  <div class="column">
                    <validation-provider
                      v-slot="{ errors }"
                      :rules= {required:true}
                      name="column"
                    >
                      <searchable-list
                        :message="errors"
                        :type="{ 'is-danger': errors[0] }"
                        :items.sync="sourceList"
                        :selectedItems.sync="varName"
                        v-model="varName"
                        :onlyOneSelection="true"
                        placeholder="Filter on individuals (rows) and variables (columns)"
                      />
                    </validation-provider>
                  </div>
                  <div class="column">
                    <div v-if="varCategories.length > 0" class="columns">
                      <div class="column">
                        <b-field label="Current categories" />
                      </div>
                      <div class="column">
                        <b-field label="New categories" />
                      </div>
                    </div>
                    <div
                      v-for="(category,index) in varCategories"
                      :key="index"
                      class="columns"
                    >
                      <div class="column">
                        <b-input expanded :disabled="true" :value="category" />
                      </div>
                      <div class="column">
                        <b-input @input="updateCategory($event, index)" expanded :value="newCategories[index]" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="action==='filter_categories'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules= {required:true}
                    name="column"
                  >
                    <searchable-list
                      :message="errors"
                      :type="{ 'is-danger': errors[0] }"
                      :items.sync="sourceList"
                      :selectedItems.sync="varName"
                      v-model="varName"
                      :onlyOneSelection="true"
                      placeholder="Filter on individuals (rows) and variables (columns)"
                    />
                  </validation-provider>
                  <hr>
                  <div v-if="varCategories.length + targetList.length > 0">
                    <b-field label="3. Select categories">
                    </b-field>
                    <hr>
                    <validation-provider
                    v-slot="{ errors }"
                    :rules="{ required: testFilter}"
                    name="column"
                    >
                     <dual-list-box
                       :message="errors"
                       :type="{ 'is-danger': errors[0] }"
                       :sourceList.sync="varCategories"
                       :targetList.sync="targetList"
                       v-model="targetList"
                     />
                    </validation-provider>
                    <div v-if="hasNA" class="columns">
                      <div class="column">
                      </div>
                      <div class="column">
                          <b-switch v-model="keepNA">
                            Keep missing values
                          </b-switch>
                      </div>
                      <div class="column">
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="action==='reorder_categories'"
                  class="columns"
                >
                  <div class="column">
                    <validation-provider
                      v-slot="{ errors }"
                      :rules= {required:true}
                      name="column"
                    >
                      <searchable-list
                        :message="errors"
                        :type="{ 'is-danger': errors[0] }"
                        :items.sync="sourceList"
                        :selectedItems.sync="varName"
                        v-model="varName"
                        :onlyOneSelection="true"
                        placeholder="Filter on individuals (rows) and variables (columns)"
                      />
                    </validation-provider>
                  </div>
                  <div class="column">
                    <draggable-list
                      :items.sync="varCategories"
                    />
                  </div>
                </div>
                <div
                  v-else-if="action==='set_rownames'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules= {required:true}
                    name="column"
                  >
                    <searchable-list
                      :message="errors"
                      :type="{ 'is-danger': errors[0] }"
                      :items.sync="sourceList"
                      :selectedItems.sync="varName"
                      v-model="varName"
                      :onlyOneSelection="true"
                      placeholder="Choose a variable (column) containing new row names"
                    />
                  </validation-provider>
                </div>
                <hr v-if="action!=='transpose'"/>
                <div class="has-text-centered">
                <b-button rounded
                  class="button is-primary"
                  @click.prevent="handleSubmit(run)"
                >
                  <span class="icon is-small"><i class="fa fa-cogs"></i></span>
                  <span> Run </span>
                </b-button>
                </div>
              </div>
            </div>
            <div v-else>
              Select a dataset.
            </div>
          </card-component>
          <div v-if="objectName">
            <dataset-view
              :dataset-name=objectName
              :display-error=false
              :reset-error=false
            />
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import natureDictionary from '../../../data/natureDictionary.json'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'

const DEFAULT_ACTION = null

export default {
  name: 'REdit',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dual-list-box': () => import('@/components/ui/DualListBox.vue'),
    'searchable-list': () => import('@/components/ui/SearchableList.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'draggable-list': () => import('@/components/ui/DraggableList.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'dataset-view': () => import('@/components/DatasetView'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    analysisObj: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      datasetNatures: natureDictionary.filter(el => el.from === 'user'),
      dataset_nature: null,
      columns_type: null,
      datasetName: null,
      varName: null,
      keepNA: false,
      action: DEFAULT_ACTION,
      history: [],
      objectName: null,
      sourceList: [],
      targetList: [],
      varCategories: [],
      newCategories: [],
      hasNA: false,
      categoricalVariables: [],
      hasCatVariables: true,
      labelDisabled: null,
      triggerDisabled: ['none'],
      retrieve: 'current',
      keep_notifications: false
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const errorStore = useErrorStore()
    return { workspaceStore, errorStore }
  },
  computed: {
    editionTitle: function () {
      if (this.datasetName == null) {
        return '2. Parameters'
      } else if (this.action === 'transpose') {
        return '2. Transpose'
      } else if (this.action === 'set_dataset_nature') {
        return '2. Change dataset nature'
      } else if (this.action === 'set_columns_type') {
        return '2. Change variable (column) types'
      } else if (this.action === 'subsetc') {
        return '2. Choose variables (columns) to keep'
      } else if (this.action === 'subsetr') {
        return '2. Choose individuals (rows) to keep'
      } else if (this.action === 'recode_categories') {
        return '2. Rename categories'
      } else if (this.action === 'reorder_categories') {
        return '2. Reorder categories'
      } else if (this.action === 'filter_categories') {
        return '2. Select a variable'
      } else if (this.action === 'set_rownames') {
        return '2. Use a variable (column) as individual (row) names'
      } else {
        return ''
      }
    },
    hasNotificationError: function () {
      return this.errorStore.hasNotificationError
    },
    testFilter: function () {
      return !this.keepNA && this.targetList.length === 0
    }
  },
  methods: {
    updateCategory: function (value, index) {
      this.newCategories[index] = value
    },
    setLists: function () {
      if (this.action === 'set_columns_type' || this.action === 'subsetc' || this.action === 'set_rownames') {
        this.sourceList = this.colNames
        this.targetList = []
        this.varName = null
        this.varCategories = []
        this.newCategories = []
        this.keepNA = false
        this.hasNA = false
      } else if (this.action === 'recode_categories' || this.action === 'reorder_categories' || this.action === 'filter_categories') {
        this.sourceList = this.categoricalVariables
        this.targetList = []
        this.varName = null
        this.varCategories = []
        this.newCategories = []
        this.keepNA = false
        this.hasNA = false
      } else if (this.action === 'subsetr') {
        this.sourceList = this.rowNames
        this.targetList = []
        this.varName = null
        this.varCategories = []
        this.newCategories = []
        this.keepNA = false
        this.hasNA = false
      } else {
        this.sourceList = []
        this.targetList = []
        this.varName = null
        this.varCategories = []
        this.newCategories = []
        this.keepNA = false
        this.hasNA = false
      }
    },
    saveDataset: function () {
      let self = this
      this.$buefy.dialog.prompt({
        message: 'Dataset name',
        inputAttrs: {
          type: 'text',
          placeholder: self.datasetName,
          maxlength: 40,
          pattern: '^[A-Za-z][A-Za-z0-9_]*$',
          title: 'Dataset name not allowed'
        },
        trapFocus: true,
        confirmText: 'Save',
        onConfirm: function (value) {
          const loadingComponent = self.$buefy.loading.open({
            container: null
          })
          self.workspaceStore.runRFunction({
            func_name: 'r_extract_dataset',
            datasetName: self.objectName,
            userName: value,
            out_graph: true
          })
            .then(data => {
              if ('Messages' in data) {
                self.errorStore.setNotifications(data.Messages.data)
              }
              if (!self.errorStore.hasNotificationError) {
                self.datasetName = data.ObjectName
                self.retrieve = 'current'
                self.keep_notifications = true
              }
              loadingComponent.close()
            })
            .catch(function () {
              loadingComponent.close()
            })
        }
      })
    },
    resetHistory: function () {
      let self = this
      this.$buefy.dialog.confirm({
        title: 'Reset history',
        message: 'Do you want to reset the history?',
        cancelText: 'Cancel',
        confirmText: 'Reset',
        onConfirm: function () {
          const loadingComponent = self.$buefy.loading.open({
            container: null
          })
          self.workspaceStore.runRFunction({
            func_name: 'r_unedit_dataset',
            datasetName: self.datasetName,
            reset_all: true,
            out_graph: true
          })
            .then(data => {
              this.rowNames = data.Rownames
              this.colNames = data.Colnames
              this.categoricalVariables = data.CatVarNames
              self.history = data.HistoryEdition
              self.objectName = data.ObjectName
              loadingComponent.close()
            })
            .catch(function () {
              loadingComponent.close()
            })
        }
      })
    },
    undo: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.objectName = null
      this.workspaceStore.runRFunction({
        func_name: 'r_unedit_dataset',
        datasetName: this.datasetName,
        out_graph: true
      })
        .then(data => {
          this.rowNames = data.Rownames
          this.colNames = data.Colnames
          this.categoricalVariables = data.CatVarNames
          this.history = data.HistoryEdition
          this.objectName = data.ObjectName
          loadingComponent.close()
        })
        .catch(function () {
          loadingComponent.close()
        })
    },
    getVarcategories: function () {
      if (this.varName) {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.workspaceStore.runRFunction({
          func_name: 'r_get_varcategories',
          datasetName: this.objectName,
          varName: this.varName[0],
          out_graph: true
        })
          .then(data => {
            this.varCategories = data.categories
            this.newCategories = [...data.categories]
            this.hasNA = data.has_na
            loadingComponent.close()
          })
          .catch(function () {
            loadingComponent.close()
          })
      }
    },
    run: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let params = {
        func_name: 'r_edit_dataset',
        datasetName: this.datasetName,
        action: this.action,
        out_graph: true,
        keep_notifications: this.keep_notifications
      }
      if (this.action === 'set_dataset_nature') {
        params['dataset_nature'] = this.dataset_nature
      } else if (this.action === 'set_columns_type') {
        params['columns_type'] = this.columns_type
        params['column_names'] = this.targetList
      } else if (this.action === 'subsetr') {
        params['action'] = 'subset'
        params['row_names'] = this.targetList
      } else if (this.action === 'subsetc') {
        params['action'] = 'subset'
        params['column_names'] = this.targetList
      } else if (this.action === 'reorder_categories') {
        params['new_categories'] = this.varCategories
        params['column_names'] = this.varName
      } else if (this.action === 'recode_categories') {
        params['new_categories'] = this.newCategories
        params['column_names'] = this.varName
      } else if (this.action === 'filter_categories') {
        params['new_categories'] = this.targetList
        params['column_names'] = this.varName
        params['keep_na'] = this.keepNA
      } else if (this.action === 'set_rownames') {
        params['column_names'] = this.varName
      }
      this.objectName = null
      this.workspaceStore.runRFunction(params)
        .then(data => {
          if ('Rownames' in data) {
            this.rowNames = data.Rownames
            this.colNames = data.Colnames
            this.categoricalVariables = data.CatVarNames
            this.history = data.HistoryEdition
            this.objectName = data.ObjectName
          }
          if ('Messages' in data) {
            this.errorStore.setNotifications(data.Messages.data)
          }
          this.action = DEFAULT_ACTION
          loadingComponent.close()
        })
        .catch(function () {
          loadingComponent.close()
        })
    },
    updateInputDataset: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.runRFunction({
        func_name: 'r_edit_dataset',
        datasetName: this.datasetName,
        retrieve: this.retrieve,
        out_graph: true,
        keep_notifications: this.keep_notifications
      })
        .then(data => {
          if ('Rownames' in data) {
            this.rowNames = data.Rownames
            this.colNames = data.Colnames
            this.categoricalVariables = data.CatVarNames
            this.history = data.HistoryEdition
            this.objectName = data.ObjectName
            this.setLists()
          }
          if ('Messages' in data) {
            this.errorStore.setNotifications(data.Messages.data)
          }
          this.keep_notifications = false
          this.retrieve = 'current'
          loadingComponent.close()
        })
        .catch(function () {
          loadingComponent.close()
        })
    }
  },
  watch: {
    datasetName: function () {
      this.updateInputDataset()
    },
    action: function () {
      this.setLists()
      this.varName = null
    },
    varName: function () {
      if (this.action !== 'set_rownames') {
        this.getVarcategories()
      }
      if (this.action === 'filter_categories') {
        this.targetList = []
        this.keepNA = false
      }
    },
    categoricalVariables: function () {
      this.hasCatVariables = (this.categoricalVariables.length > 0)
      this.labelDisabled = null
      this.triggerDisabled = ['none']
      if (!this.hasCatVariables) {
        this.labelDisabled = 'No categorical variables in the dataset'
        this.triggerDisabled = ['hover']
      }
    }
  },
  filters: {
    truncateEntry: function (value, end) {
      if (value.length > end + 3) {
        return (value.substring(0, end) + '...')
      } else {
        return value
      }
    }
  },
  mounted () {
    if (!this.workspaceStore.hasDataset) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.getWorkflow()
        .then(() => {
          loadingComponent.close()
        })
        .catch(() => {
          loadingComponent.close()
        })
    }
    if (this.analysisObj !== null) {
      this.retrieve = this.analysisObj.meta.func_args.retrieve
      this.datasetName = this.analysisObj.meta.func_args.datasetName
    }
  }
}
</script>

<style scoped>
ul.striped-list > li {
  list-style-type: none;
  padding: 6px;
  display: flex;
  align-items: center;
}
ul.striped-list > li:nth-of-type(odd) {
  background-color: #f2f4f3 ;
}
</style>
